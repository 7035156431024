<template>
  <div>
    <div class="bjtbox"></div>
    <div class="bjttextbox">
      <!--  -->

      <div class="bjttextimg">
        <div class="titlebox">
          <div class="text1">品牌概述</div>
          <div class="text2">BRAND OVERVIEW</div>
          <div class="text3">
           {{ pagedata.memo }}
          </div>
          <div class="text4">
           {{ pagedata.en_memo }}
          </div>

        </div>
      </div>
      <!-- <img src="@/assets/page/bjt7.png" alt=""> -->
    </div>

    <!-- 品牌发展 -->
    <div class="brand">
      <div class="poadw"></div>
      <div class="development">
        <div class="tile">品牌发展</div>
        <div class="fbiti">BRANG DEVELOPMENT</div>
      </div>
      <!-- 视频 -->
      <video
        controls
        height="1080px"
        width="100%"
        :src="pagedata.video"
      ></video>
    </div>
    <!-- 品质介绍 轮播图 -->
    <div class="bannabox">
      <div class="qualitybox">
        <div class="t1">品质介绍</div>
        <div class="t2">BRAND OVERVIEW</div>
      </div>
      <div class="gundbox" @mousedown="admous" @mousemove="admove" @mouseup="adseup">
        <img
          :style="{ left: leftpx + 'px' }"
          :src="pagedata.pc_image"
          alt=""
        />
      </div>
      <div class="shuxbox">
        <img src="@/assets/page/shux.png" alt="" />
      </div>
    </div>
    <!-- 品牌愿景 -->
    <div class="vision">
      <div class="t1">品牌愿景</div>
      <div class="t2">BRAND VISION</div>
      <div class="t3">
        {{ pagedata.vision }}
      </div>
      <div class="t4">
        {{ pagedata.en_vision }}
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="titlexb">
        <div class="t1">合作伙伴</div>
        <div class="t2">COOPERATIVE PARTNERr</div>
      </div>
      <div class="listbox">
        <div class="itembox" v-for="(item, index) in pagedata.shipList" :key="index">
         <a :href="item.url" target="_blank"> <img :src="item.logo" alt="" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hostDetail } from '../../service/request/homepage'
export default {
  data() {
    return {
      ismove: false, //判断是是否是鼠标按下才开始移动
      leftpx: 0, //照片的移动条件
      seatX: 0, //当前鼠标的位置

      xadd: true,
      radd: true,

      pagedata:{},//初始数据
    }
  },
  components: {},
  mounted() {
    this.hostDetail()
  },
  methods: {
    // 初始数据
    hostDetail() {
      hostDetail().then((res) => {
        console.log('22', res)
        this.pagedata=res.msg
      })
    },

    // 鼠标按下
    admous(e) {
      this.ismove = true
      this.xadd = true
      this.radd = true
      this.seatX = 0
      this.seatX = e.screenX - 85
    },
    // 鼠标移动
    admove(e) {
      if (this.ismove) {
        if (e.screenX > this.seatX) {
          if (this.xadd) {
            this.leftpx += 3
          }
          console.log(this.leftpx)
          if (this.leftpx > 80) {
            this.leftpx = 0
            this.xadd = false
          }
        } else {
          if (this.radd) {
            this.leftpx -= 3
          }
          console.log(this.leftpx)
          if (this.leftpx < -730) {
            this.leftpx = -627
            this.radd = false
          }
        }
      }
    },
    // 鼠标抬起
    adseup() {
      this.ismove = false
    }
  }
}
</script>
<style scoped lang="less">
.bjtbox {
  /* width: 100%; */
  width: 100%;
  height: 1080px;
  background: url('../../assets/page/lyh.png') no-repeat no-repeat;
  background-size: 100% 100%;
}
.bjttextbox {
  /* width: 100vw; */
  width: 100%;
  position: relative;
  min-height: 468px;
  .bjttextimg {
    background: url('../../assets/page/bjt7.png') no-repeat;
    background-size: 100% 100%;
    // position: absolute;
    // z-index: 0;
    // top: 0;
    // left: 0;
    // height: 468px;
    min-height: 468px;
    z-index: -10;
    width: 100%;
    // img{
    //   height: 468px;
    //   width: 100%;
    // }
  }
  .titlebox {
    width: 1400px;
    margin: 0 auto;
    // z-index: 99999;
    text-align: center;
    // position: absolute;
    // left: 50%;
    // margin-left: calc(-87.5rem / 2);

    .text1 {
      padding-top: 79px;
      color: #fff;
      font-size: 36px;
    }
    .text2 {
      margin-top: 21px;
      color: #aaaaaa;
      font-size: 16px;
    }
    .text3 {
      margin-top: 58px;
      color: #eeeeee;
      font-size: 16px;
    }
    .text4 {
      margin-top: 51px;
      color: #eeeeee;
      font-size: 16px;
    }
  }
}
.brand {
  width: 100%;
  height: 1392px;
  position: relative;
  background: url('../../assets/page/bjt8.png') no-repeat no-repeat;
  background-size: cover;
  text-align: center;
  .poadw {
    width: 260px;
    height: 30px;
    background-color: #3e907c;
    position: absolute;
    top: -15px;
    left: 0px;
  }
  .development {
    width: 100%;
    text-align: center;
    .tile {
      width: 300px;
      margin: 0 auto;
      color: #000000;
      font-size: 36px;
      padding-top: 70px;
    }
    .fbiti {
      margin: 22px auto 35px;
      width: 500px;
      color: #999999;
      font-size: 16px;
      /* padding: 22px 0 35 0px; */
    }
  }
}
.bannabox {
  width: 100%;
  height: 1020px;
  background: url('../../assets/page/bjt9.png') no-repeat;
  background-size: cover;
  .qualitybox {
    margin: 0 auto;
    text-align: center;
    .t1 {
      font-size: 36px;
      color: #ffffff;
      padding-top: 96px;
      padding-bottom: 21px;
    }
    .t2 {
      color: #aaaaaa;
      font-size: 16px;
      padding-bottom: 77px;
    }
  }
  .gundbox {
    cursor: pointer;
    margin: 0 85px;
    height: 650px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    img {
      top: 0;
      left: 0px;
      pointer-events: none;
      position: absolute;
      width: 2420px;
      height: 650px;
    }
  }
  .shuxbox {
    margin: 0 85px;
    height: 100px;
    position: relative;
    img {
      width: 184px;
      height: 8px;
      position: absolute;
      right: 10px;
      top: 42px;
    }
  }
}
.vision {
  background-color: #fff;
  .t1 {
    width: 360px;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    color: #000000;
    padding-top: 68px;
  }
  .t2 {
    width: 360px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999999;
    margin-top: 15px;
  }
  .t3 {
    width: 1227px;

    text-align: center;
    color: #333333;
    font-size: 16px;
    margin: 67px auto 0;
  }
  .t4 {
    width: 1217px;
    margin: 50px auto 0;
    text-align: center;
    color: #333333;
    font-size: 16px;
    margin-bottom: 80px;
  }
}
.partner {
  background-color: #fafafa;
  .titlexb {
    .t1 {
      width: 360px;
      margin: 0 auto;
      text-align: center;
      color: #333333;
      font-size: 36px;
      padding-top: 81px;
    }
    .t2 {
      width: 360px;
      /* margin: 0 auto; */
      text-align: center;
      color: #999999;
      font-size: 16px;
      margin: 15px auto 0;
      // padding-bottom: 30px;
    }
  }
  .listbox {
    padding-bottom: 77px;
    margin: 82px 85px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .itembox {
    cursor: pointer;
    margin-right: 70px;
    margin-top: 76px;
    width: 380px;
    height: 190px;
    img {
      width: 380px;
      height: 190px;
    }
  }
  .itembox:nth-child(4n) {
    margin-right: 0;
  }
  .itembox:nth-child(1) {
    margin-top: 0;
  }
  .itembox:nth-child(2) {
    margin-top: 0;
  }
  .itembox:nth-child(3) {
    margin-top: 0;
  }
  .itembox:nth-child(4) {
    margin-top: 0;
  }
}
</style>
